<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icons.mdiDelete }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      width="600px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >セット（削除）</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-if="apierror.status == 'error'">
              <div
                v-for="msg in apierror.messages"
                :key="msg"
              >
                <v-row class="ml-6 mb-3 ma-3">
                  <span style="color: red">* {{ msg }} </span>
                </v-row>
              </div>
            </template>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col cols="12">
                <v-text-field
                  v-model="selectedSet.set_code"
                  label="セットコード"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="selectedSet.set_name"
                  label="セット名"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="d-flex justify-center">
          <v-btn
            color="success"
            :loading="submitStatus"
            @click="deleteItem(selectedSet.set_id)"
          >
            YES
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiDelete,
} from '@mdi/js'
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'

export default {
  props: ['id'],
  data: () => ({
    dialog: false,
    submitStatus: false,
    files: [],
    icons: {
      mdiDelete,
    },
    sellStatus: [
      {
        text: '販売不可', value: 0,
      },
      {
        text: '販売可', value: 1,
      },
    ],
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  computed: {
    ...mapState('setStore', ['selectedSet']),
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('setStore', ['loadSet', 'deleteSet']),
    openForm() {
      this.setOverlayStatus(true)
      this.loadSet(this.id)
        .catch(error => {
          this.submitStatus = false
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        })
        .finally(() => {
          this.setOverlayStatus(false)
          this.dialog = true
        })
    },
    closeForm() {
      this.$emit('refresh')
      this.apierror.messages = []
      this.dialog = false
    },
    deleteItem(id) {
      this.apierror.messages = []
      this.submitStatus = true
      this.deleteSet(id)
        .then(() => {
          this.closeForm()
        }).catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.submitStatus = false
        })
    },
  },
}
</script>
