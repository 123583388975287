<template>
  <div justify="center">
    <v-icon
      small
      @click="openForm"
    >
      {{ icons.mdiEye }}
    </v-icon>

    <v-dialog
      v-model="dialog"
      :height="dialogHeight"
      scrollable
      persistent
    >
      <v-card tile>
        <v-toolbar
          class="d-flex justify-end"
          height="36px"
          color="#E1F5FE"
        >
          <v-btn
            icon
            @click="closeForm"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card
          class="overflow-y-auto"
          height="100%"
          tile
        >
          <template v-if="apierror.status == 'error'">
            <div
              v-for="msg of apierror.messages"
              :key="msg"
            >
              <v-row class="ml-6 mb-3 ma-3">
                <span style="color: red">* {{ msg }} </span>
              </v-row>
            </div>
          </template>
          <v-container
            style="padding:0px;max-width:100% !important"
          >
            <v-img
              width="100%"
              position="relative"
              :src="set.image ? set.image : require('@/assets/images/noimage.jpg')"
            ></v-img>
            <div
              style="
                margin-top: 25px;
                line-height: 50px;
                text-align: center;
                font-family: 'Noto Sans JP', sans-serif;
                font-style: normal;
                font-weight: 900;
                font-size: 30px;
                color: black;
                white-space: pre-line;
              "
            >
              <span>{{ set.set_name }}</span>
              <br />
              <span class="ml-6" style="font-size: 25px">{{ set.set_tip.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') }}円（送料・税込み）</span>
            </div>
            <div v-html="set.set_overview"></div>
            <div
              class="mt-12"
              style="max-width: 1366px; margin: auto; margin-top: -30px"
            >
              <v-row v-for="(line, index) in setList" :key="index">
                <v-spacer></v-spacer>
                <v-col v-for="(item, i) in line" :key="i" cols="6" md="3" class="pa-5 pointer">
                  <v-img
                    alt="BimiStock（ビミストック）は全国の美味しい保存食を3か月毎お届けする普段使いの防災食定期便です。"
                    :src="item.image"
                  ></v-img>
                  <div class="text" style="white-space: pre-line">
                    <span>{{ item.item_name }}</span>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              <div class="d-flex justify-center my-12">
                <v-btn
                  depressed
                  rounded
                  style="background-color: #ff6e40; width: 300px; min-height: 120px"
                >
                  <div
                    style="
                      color: #fff;
                      line-height: 47px;
                      font-family: 'Noto Sans JP', sans-serif;
                      font-style: normal;
                      font-weight: 900;
                      font-size: 35px;
                    "
                  >
                    <span>購入する</span>
                  </div>
                </v-btn>
              </div>
            </div>
          </v-container>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiEye,
  mdiClose,
} from '@mdi/js'
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'

export default {
  props: ['id'],
  data: () => ({
    dialog: false,
    images: [],
    set: {
      set_name: '',
      set_code: '',
      saleable: '',
      details: [],
      set_images: '',
      image: '',
      set_overview: '',
      set_desc: '',
      set_up_show: '',
      set_usual_price: '',
      set_tep_show: '',
      set_tep: '',
      set_dr_show: '',
      set_dr_value: '',
      set_ctr: '',
      set_tip: '',
      set_ssd: '',
      set_sed: '',
      set_eos: '',
    },
    setList: [],
    selectedImg: '',
    icons: {
      mdiEye,
      mdiClose,
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),

  computed: {
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('setStore', ['selectedSet', 'setItems']),
    dialogHeight() {
      return document.documentElement.clientHeight * 0.9
    },
  },

  watch: {
  },

  created() {
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('setStore', ['loadSet', 'loadSetItems']),
    openForm() {
      this.loadData()
    },
    closeForm() {
      this.dialog = false
      this.clearData()
      this.$emit('refresh')
    },
    clearData() {
      this.setList = []
      this.set = {
        set_name: '',
        set_code: '',
        saleable: '',
        details: [],
        set_images: '',
        image: '',
        set_overview: '',
        set_desc: '',
        set_up_show: '',
        set_usual_price: '',
        set_tep_show: '',
        set_tep: '',
        set_dr_show: '',
        set_dr_value: '',
        set_ctr: '',
        set_tip: '',
        set_ssd: '',
        set_sed: '',
        set_eos: '',
      }
    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadSet(this.id),
        this.loadSetItems(this.id),
      ]).then(() => {
        console.log('loadSet', this.id, this.selectedSet)
        this.set = {
          ...this.selectedSet,
        }
        if (this.set.set_images) {
          this.set.image = `${this.set.imgUrl_prefix}/${this.set.set_images.split('"')[1].replace(/^\//g, '')}`
        }

        const Items = [...this.setItems]
        this.setItems.forEach((si, i) => {
          if (si.images) {
            Items[i].image = `${si.imgUrl_prefix}/${si.images.replaceAll('"', '').split(',')[0].replace(/^\//g, '')}`
          }
        })
        const showStyle = this.set.show_style
        if (showStyle) {
          const len = showStyle.length
          for (let i = 0; i < len; i += 1) {
            this.setList.push(Items.splice(0, showStyle[i]))
          }
        } else {
          this.setList.push(Items)
        }
      }).catch(error => {
        console.log('error', error)

        this.apierror.status = 'error'
        if (error.response) {
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.setOverlayStatus(false)
        this.dialog = true
      })
    },
  },
}
</script>

<style scoped>
.setOversize >>> table, .setOversize >>> table tr th, .setOversize >>> table tr td {
  border:1px solid #ccc;
  border-collapse: collapse;
}
.v-card.on-hover {
  border-color: #e77600;
  box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
}
/* .setOversize >>> img {
  width: 100%
}

.setDesc >>> img {
  width: 100%
} */

.dialogHeader {
  /* position: fixed;
  height: 36px !important;
  top: 0;
  z-index: 1000; */
}

.ImgArea {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.mainImgCard {
  /* height: 500px; */
  text-align: center;
}

.mainImg {
  object-fit: contain;
  vertical-align: top;
  /* height: 100%; */
  width: 100%;
}
</style>
