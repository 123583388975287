<template>
  <div
    id="productset-list"
    class="overflow-y-auto"
  >
    <v-card>
      <v-card-title>セット（一覧）</v-card-title>
      <v-row class="px-2 ma-0">
        <!-- <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="set_name"
            :items="setNames"
            placeholder="セット名"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col> -->
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="set_code"
            :items="setCodes"
            placeholder="セットコード"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="saleable"
            :items="saleableStatus"
            placeholder="販売可否"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateStartMenu"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :nudge-right="40"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="販売開始日"
                dense
                hide-details
                readonly
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="販売終了日"
                dense
                outlined
                hide-details
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'itemset-create', params: { query: this.$route.query } }"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="setList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.set_name`]="{ item }">
          <label>
            {{ item.set_name }}
          </label>
        </template>
        <template v-slot:[`item.set_image`]="{ item }">
          <v-img
            :src="item.set_image ? item.set_image : require('@/assets/images/noimage.jpg')"
            max-height="50"
            max-width="50"
            style="margin: 0 auto 0 0"
          ></v-img>
        </template>
        <template v-slot:[`item.saleable`]="{ item }">
          <label>{{
            getSaleable(item.saleable)
          }}</label>
        </template>
        <template v-slot:[`item.set_tip`]="{ item }">
          <label>{{ item.set_tip ? item.set_tip.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : "" }}</label>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <ItemSetPreview
              :id="item.set_id"
              class="py-2 pl-1"
              @refresh="loadData"
            ></ItemSetPreview>
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <DeleteItemSet
              :id="item.set_id"
              class="py-2 pl-1"
              @refresh="loadData"
            ></DeleteItemSet>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/*eslint-disable*/
import {
  mdiCalendar,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiExportVariant,
  mdiEye,
} from '@mdi/js'
import { mapActions, mapState, mapMutations } from 'vuex'
import DeleteItemSet from '@/views/bimi/product/ItemSet/ItemSetDelete.vue'
import ItemSetPreview from '@/views/bimi/product/ItemSet/ItemSetPreview.vue'
/*eslint-disable*/
export default {
  components: {
    DeleteItemSet,
    ItemSetPreview,
  },
  data: () => ({
    dialog: false,
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    set_name: '',
    set_code: '',
    saleable: '',
    search: '',
    saleableStatus: [
      { text: '販売不可', value: 0 },
      { text: '販売可', value: 1 },
    ],
    saleables: ['否', '可'],
    icons: {
      mdiCalendar,
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiExportVariant,
      mdiEye,
    },
    headers: [
      {
        text: 'イメージ',
        value: 'set_image',
        align: 'left',
        width: '8%',
        sortable: false,
        fixed: true,
      },
      {
        text: 'セットID',
        value: 'set_id',
        width: '8%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: 'セットコード',
        value: 'set_code',
        width: '13%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: 'セット名',
        value: 'set_name',
        width: '26%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '販売',
        value: 'saleable',
        width: '7%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '販売価格(円)',
        value: 'set_tip',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
      {
        text: '販売開始日',
        value: 'set_ssd',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '販売終了日',
        value: 'set_sed',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '8%',
        align: 'center',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('setStore', ['setList', 'setCodes', 'setNames']),
    getSaleable() {
      return function(saleable) {
        if (saleable === 1) return '可'
        return '否'
      }
    },
  },

  watch: {
    set_name(newValue) {
      this.changeRouteQuery('set_name', newValue)
      this.loadData()
    },
    set_code(newValue) {
      this.changeRouteQuery('set_code', newValue)
      this.loadData()
    },
    saleable(newValue) {
      if (typeof newValue === 'number') {
        this.changeRouteQuery('saleable', newValue.toString())
      } else {
        this.changeRouteQuery('saleable', newValue)
      }
      this.loadData()
    },
    startDate(newValue) {
      this.changeRouteQuery('startDate', newValue)
      this.loadData()
    },
    endDate(newValue) {
      this.changeRouteQuery('endDate', newValue)
      this.loadData()
    },
  },

  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  destroyed() {
    this.clearSetList()
    this.clearSetCodes()
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('setStore', ['loadSetList', 'loadSetCodes']),
    ...mapMutations('setStore', ['clearSetList', 'clearSetCodes']),
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadSetCodes(),
        this.loadSetList(this.$route.query),
      ]).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({ path: this.$route.path, query })
    },

    loadQuery() {
      this.set_name = this.$route.query.set_name ?? ''
      this.set_code = this.$route.query.set_code ?? ''

      this.saleable = this.$route.query.saleable ? parseInt(this.$route.query.saleable) : ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },

    editItem(item) {
      this.$router.push({ name: 'itemset-edit', params: { id: item.set_id, query: this.$route.query } })
    },
    // getColor(statusColor) {
    //   if (statusColor === '販売中') {
    //     return 'success'
    //   }
    //   if (statusColor === '未販売') {
    //     return 'warning'
    //   }

    //   return 'error'
    // },
  },
}
</script>
<style>
td > .action {
  padding: 0 !important;
}
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
